import React from "react";

import MelhorCompraIcon from "../../images/melhor-compra.svg";
import Connect from "../../images/connect_logo.svg";
import XcommTypo from "../../images/xcomm-tipografia.svg";
import {
  CompaniesContainer,
  CompaniesH1,
  CompaniesWrapper,
  CompaniesCard,
  CompaniesIcon,
  CompaniesH2,
  CompaniesP,
} from "./styles";

const Companies = () => {
  return (
    <>
      <CompaniesContainer id="companies">
        <CompaniesH1>Nossas empresas</CompaniesH1>
        <CompaniesWrapper>
          <CompaniesCard
            href="https://xcomm.com.br/?utm_source=tseller&utm_medium=site&utm_campaign=banner"
            target="_blank"
          >
            <CompaniesIcon src={XcommTypo} />
            <CompaniesH2>Xcomm</CompaniesH2>
            <CompaniesP>Excelência em e-commerce</CompaniesP>
          </CompaniesCard>
          <CompaniesCard
            href="https://www.melhorcompra.com.br/?utm_source=tseller&utm_medium=site&utm_campaign=banner"
            target="_blank"
          >
            <CompaniesIcon src={MelhorCompraIcon} />
            <CompaniesH2>Melhor Compra</CompaniesH2>
            <CompaniesP>Melhor preço, melhor entrega, MELHOR COMPRA</CompaniesP>
          </CompaniesCard>
          <CompaniesCard href="/" target="_blank">
            <CompaniesIcon src={Connect} />
            <CompaniesH2>Connect Logística</CompaniesH2>
            <CompaniesP>
              Eficiência operacional e logística para e-commerce
            </CompaniesP>
          </CompaniesCard>
        </CompaniesWrapper>
      </CompaniesContainer>
    </>
  );
};

export default Companies;
