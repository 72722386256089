import { Switch } from "react-router-dom";
import Route from "./Route";
import Home from "../pages/index";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route
        path="/painel"
        exact
        component={() => {
          window.location.href =
            "https://sites.google.com/tseller.com.br/controle/home";
          return null;
        }}
      />
      {/* <Route path="/crms" exact component={CrmForm} />
      <Route path="/marketplaces" exact component={B2wForm} />
      <Route path="/info" exact component={InfoCard} />
      <Route
        path="/"
        component={() => <h1 style={{ marginLeft: "16rem" }}>404</h1>}
      /> */}
    </Switch>
  );
}
