import { Route } from "react-router-dom";

export const RouteWrapper = ({ Component, isPrivate = false, ...rest }) => {
  // const { user, setUser } = useUserContext();

  // useEffect(() => {
  //   setUser(getUser());
  //   retrieveWebToken();
  //   //eslint-disable-next-line
  // }, []);

  // if (!user?.signed && isPrivate) {
  //   return <Redirect to="/login" />;
  // }

  // if (user?.signed && !isPrivate) {
  //   return <Redirect to="/" />;
  // }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default RouteWrapper;
