import React from "react";
import Video from "../../videos/Video.mp4";
import TopSellerLogo from "../../images/logo-light.svg";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
} from "./styles";

const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1 src={TopSellerLogo} />
        <HeroP>Excelência de ponta a ponta</HeroP>
        <p style={{ display: "none" }}>site no cloud</p>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
