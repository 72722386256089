import React, { useState } from "react";
import formImage from "../../images/form.svg";
import { BsFillPersonFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import emailjs from "emailjs-com";

import {
  ContactUsContainer,
  FormsRow,
  Form,
  Input,
  Img,
  FormTitle,
  TextArea,
  IconWrapper,
  IconInputWrapper,
  Column1,
  Column2,
  FormsWrapper,
  ImgWrap,
} from "./styles";

import { Button } from "../ButtonElements";

function TalkForm() {
  const [icon, setIcon] = useState(false);
  const [iconMail, setIconMail] = useState(false);
  const [sending, setSending] = useState(false);

  const handleSubmit = (e) => {
    console.log(e.target);
    e.preventDefault();
    setSending(true);
    setTimeout(() => setSending(false), 1000);

    emailjs
      .sendForm(
        "service_dkq2rcv",
        "template_9lf6olg",
        e.target,
        "user_jiBsDMEO2Ttuk6LcIwO47"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const toggleNameOn = () => {
    setIcon(true);
  };

  const toggleNameOff = () => {
    setIcon(false);
  };

  const toggleMailOn = () => {
    setIconMail(true);
  };

  const toggleMailOff = () => {
    setIconMail(false);
  };

  return (
    <>
      <ContactUsContainer id="talktous">
        <FormsWrapper>
          <FormsRow>
            <Column1>
              <Form onSubmit={handleSubmit}>
                <Input autoComplete="on" style={{ display: "none" }} />
                <FormTitle>Fale conosco</FormTitle>
                <IconInputWrapper>
                  <IconWrapper isSelected={icon}>
                    <BsFillPersonFill />
                  </IconWrapper>
                  <Input
                    name="name"
                    placeholder="Nome"
                    onFocus={toggleNameOn}
                    onBlur={toggleNameOff}
                    autoComplete="nope"
                  />
                </IconInputWrapper>
                <IconInputWrapper>
                  <IconWrapper isSelected={iconMail}>
                    <GrMail />
                  </IconWrapper>

                  <Input
                    name="email"
                    placeholder="E-mail"
                    onFocus={toggleMailOn}
                    onBlur={toggleMailOff}
                    autoComplete="nope"
                  />
                </IconInputWrapper>
                <TextArea name="message" placeholder="Escreva sua mensagem" />
                <Button type="submit" borderRadius="8px" dark bold>
                  {sending ? "Message delivered!" : "Send"}
                </Button>
              </Form>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={formImage} />
              </ImgWrap>
            </Column2>
          </FormsRow>
        </FormsWrapper>
      </ContactUsContainer>
    </>
  );
}

export default TalkForm;
