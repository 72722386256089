import styled, { css } from "styled-components";
import { darken, lighten } from "polished";

export const Button = styled.button`
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "50px"};
  background: ${({ primary }) => (primary ? "#10184f" : "#2bbcbb")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#010606")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary ? lighten(0.08, "#10184f") : darken(0.08, "#2bbcbb")};
    color: #fff;
  }
`;
