export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "UM NOVO CONCEITO DE E-COMMERCE",
  headline:
    "Excelência e gestão de ponta a ponta para levar a melhor experiência até você",
  description: "Viva conosco uma nova maneira de comprar produtos on-line.",
  buttonLabel: "Conheça-nos? Talvez",
  imgStart: false,
  img: require("../../images/svg2.svg").default,
  alt: "Coding",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjTwo = {
  id: "talktous",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Escrever algo legal",
  headline: "Escrever algo mais legal ainda, porque é maior",
  description:
    "Descrever algo legal sobre a empresa, algo que expresse nosso vício por perfeição e tal",
  buttonLabel: "Conheça-nos? Talvez",
  imgStart: false,
  img: require("../../images/svg2.svg").default,
  alt: "Coding",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjThree = {
  id: "talktous",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Escrever algo legal",
  headline: "Escrever algo mais legal ainda, porque é maior",
  description:
    "Descrever algo legal sobre a empresa, algo que expresse nosso vício por perfeição e tal",
  buttonLabel: "Conheça-nos? Talvez",
  imgStart: false,
  img: require("../../images/svg2.svg").default,
  alt: "Coding",
  dark: true,
  primary: true,
  darkText: false,
};
