import { Frame, Container } from "./styles";

function YoutubeVideo() {
  return (
    <Container>
      <Frame
        width="560"
        height="315"
        src="https://www.youtube.com/embed/lTnyPhKuYqs?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      ;
    </Container>
  );
}

export default YoutubeVideo;
