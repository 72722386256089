import styled from "styled-components";

export const CompaniesContainer = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;

  @media (max-width: 768px) {
    height: 1300px;
  }

  @media (max-width: 480px) {
    height: 1300px;
  }
`;

export const CompaniesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const CompaniesCard = styled.a`
  height: 300px;
  background: #121214;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const CompaniesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 20px;
`;

export const CompaniesH1 = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 64px;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const CompaniesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #fff;
`;

export const CompaniesP = styled.p`
  /* margin-top: 1rem; */
  font-size: 1rem;
  text-align: center;
  color: #fff;
  line-height: 1.1;
`;
