import React from "react";
import { FaWhatsapp, FaLinkedin, FaMountain } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { FiMapPin } from "react-icons/fi";
import topSellerLogo from "../../images/logo-light.svg";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  IconTextWrap,
  PinIconWrap,
  PinIconTextWrap,
  Logo,
  IconTextWrapSecond,
} from "./styles";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Contato</FooterLinkTitle>
                <IconTextWrap>
                  <AiOutlineMail />
                  <FooterLink>contato@tseller.com.br</FooterLink>
                </IconTextWrap>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Endereço</FooterLinkTitle>
                <PinIconTextWrap>
                  <PinIconWrap>
                    <FiMapPin />
                  </PinIconWrap>
                  <FooterLink>
                    Av. Nossa Sra. dos Navegantes, 955 - Enseada do Suá, Vitória
                    - ES, 29050-335
                  </FooterLink>
                </PinIconTextWrap>
              </FooterLinkItems>
            </FooterLinksWrapper>

            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Institucional</FooterLinkTitle>
                <PinIconTextWrap>
                  <FaMountain />
                  <FooterLink>
                    Top Seller Gestão e Consultoria em Ecommerce LTDA
                  </FooterLink>
                </PinIconTextWrap>

                <IconTextWrapSecond>
                  <CgFileDocument />
                  <FooterLink>CNPJ: 40.049.964/0001-49</FooterLink>
                </IconTextWrapSecond>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo>
                <Logo src={topSellerLogo} />
              </SocialLogo>

              <SocialIcons>
                <SocialIconLink
                  href="https://www.linkedin.com/company/topseller/"
                  target="_blank"
                  aria-label="Linkedin"
                >
                  <FaLinkedin />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" aria-label="Whatsapp">
                  <FaWhatsapp />
                </SocialIconLink>
              </SocialIcons>
              <WebsiteRights>
                Top Seller © {new Date().getFullYear()} Todos os direitos
                reservados
              </WebsiteRights>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
