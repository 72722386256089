import styled from "styled-components";

export const ContactUsContainer = styled.div`
  color: #fff;
  background: #f9f9f9;
  padding: 0 100px;

  @media (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const FormsWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: calc(100vh);
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const FormsRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col1 col2'` : `'col2 col1'`};

  @media (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart
        ? `'col1' 
    'col2'`
        : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 32px;
  background-color: #202024;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0 0 0 0;
  }
`;

export const FormWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const FormTitle = styled.h1`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
`;

export const Input = styled.input`
  background-color: #121214;
  border-radius: 8px;
  height: 50px;
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  margin-bottom: 8px;
  align-items: center;
  width: 92%;
  margin-left: 20px;
`;

export const IconInputWrapper = styled.div`
  border-radius: 8px;
  border: 2px solid #121214;
  height: 54px;
  margin-bottom: 8px;
  background-color: #121214;
  width: 100%;

  &:focus-within {
    border: 2px solid #2bbcbb;
    outline: 0;
  }
`;

export const IconWrapper = styled.i`
  height: 50px;
  color: ${({ isSelected }) => (isSelected ? "#2bbcbb" : "#6a6a6a")};
  background: #121214;
  padding: 15px 10px;
  border-radius: 8px;
  position: absolute;
  /* border-right: 10px; */
`;

export const TextArea = styled.textarea`
  background-color: #121214;
  border-radius: 8px;
  color: #fff;
  padding: 12px 16px;
  font-size: 14px;
  border: 2px solid #121214;
  height: 150px;
  margin-bottom: 16px;
  resize: none;

  &:focus {
    border: 2px solid #2bbcbb;
    outline: 0;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const ImgWrap = styled.div`
  max-width: 500px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
